<template>
  <div class="dashboard-content" data-app>
    <!-- Content -->
    <div class="card">
      <div class="filters" style="padding: 10px 10px 0px 10px">
        <h3 class="text-center">
          {{ title
          }}<v-tooltip top v-if="admin === 1">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="#0c2c7c"
                dark
                class="mb-2"
                style="float: right"
                @click.prevent="handleCreate()"
                >{{ $t('Views.AdminDirectors.createButton') }}
              </v-btn></template
            >
            <span>Crer cargo</span>
          </v-tooltip>
        </h3>
      </div>
      <simple-list
        :dense="false"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="''"
        :itemKey="'id'"
        :hideFooter="true"
        :hideHeader="false"
        :multiSort="false"
        :loading="loading"
        :class="'mb-2'"
        :sortBy="''"
        :page="page"
        :pages="pages"
        :extraButtoms="false"
        :showCreate="true"
        :showDelete="admin === 1 ? true : false"
        :orderOptions="orderOptions"
        :orderBy="orderBy"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
      />
    </div>
    <modal name="crudModal" height="auto" :width="'80%'" :adaptive="true">
      <div class="card" style="padding: 2rem">
        <h3 class="text-center" v-if="flow == 'create'" style="margin-bottom: 20px">
          {{ $t('Views.AdminDirectors.createText') }}
        </h3>
        <h3 class="text-center" v-if="flow == 'edit'" style="margin-bottom: 20px">
          {{ $t('Views.AdminDirectors.editText') }}
        </h3>
        <v-form ref="form" v-model="valid" style="width: 100%">
          <v-row class="mx-0">
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :readonly="admin === 0"
                :counter="140"
                :rules="[rules.required, rules.max]"
                :label="$t('Views.AdminDirectors.labelName')"
                v-model="name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :readonly="admin === 0"
                :counter="140"
                :rules="[rules.required, rules.max]"
                :label="$t('Views.AdminDirectors.labelLastname')"
                v-model="lastname"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :readonly="admin === 0"
                :counter="140"
                :rules="[rules.required, rules.email]"
                :label="$t('Views.AdminDirectors.labelEmail')"
                v-model="email"
                type="email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :readonly="admin === 0"
                :counter="140"
                :rules="[rules.required, rules.max]"
                :label="$t('Views.AdminDirectors.labelPosition')"
                v-model="position"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :readonly="admin === 0"
                :counter="140"
                :rules="[rules.required, rules.max]"
                :label="$t('Views.AdminDirectors.labelPeriod')"
                v-model="period"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :readonly="admin === 0"
                :rules="[rules.required]"
                :label="$t('Views.AdminDirectors.labelOrder')"
                v-model="order"
                type="number"
                min="1"
                step="1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-row no-gutters>
                <span style="width: 20%"
                  >{{ $t('Views.AdminCompanies.labelCountry') }}:</span
                >
                <multiselect
                  style="width: 80%"
                  track-by="id"
                  :custom-label="countryName"
                  :placeholder="$t('Views.AdminPublications.placeholder')"
                  v-model="country"
                  :options="countries"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preserve-search="false"
                ></multiselect
              ></v-row>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-row class="m-0">
                <v-img
                  v-if="flow == 'edit' && item.image"
                  :src="item.image.route"
                  :alt="item.name"
                  style="width: 40%"
                ></v-img>
                <v-file-input
                  v-if="admin === 1"
                  v-model="image"
                  chips
                  :rules="[rules.fileSize]"
                  show-size
                  accept="image/png, image/jpeg, image/jpg"
                  :label="$t('Views.AdminDirectors.labelImage')"
                  style="width: 60%"
                ></v-file-input>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mx-0">
            <v-btn
              :dark="valid"
              style="margin: auto; margin-top: 10px"
              v-if="admin === 1 && flow == 'create'"
              :disabled="!valid"
              color="#0c2c7c"
              @click="createItem()"
            >
              {{ $t('Views.AdminDirectors.createButton') }}
            </v-btn>
            <v-btn
              :dark="valid"
              style="margin: auto; margin-top: 10px"
              v-if="admin === 1 && flow == 'edit'"
              :disabled="!valid"
              color="#0c2c7c"
              @click="saveItem()"
            >
              {{ $t('Views.AdminDirectors.saveButton') }}
            </v-btn>

            <v-btn
              v-if="admin === 1"
              dark
              style="margin: auto; margin-top: 10px"
              color="#0c2c7c"
              @click="reset"
            >
              {{ $t('Views.AdminDirectors.cleanButton') }}
            </v-btn>
            <v-btn
              dark
              style="margin: auto; margin-top: 10px"
              color="#0c2c7c"
              @click="$modal.hide('crudModal')"
            >
              {{ $t('Views.AdminDirectors.cancelButton') }}
            </v-btn>
          </v-row>
        </v-form>
      </div>
    </modal>
  </div>
</template>

<script>
  import SimpleList from 'Components/Listing/SimpleList.vue'

  export default {
    name: 'Directors',
    props: ['admin', 'type', 'association'],
    components: {
      SimpleList,
    },
    data() {
      return {
        config: {},
        loading: false,
        items: [],
        company: '',
        countries: [{ id: null, name: 'Selecciona' }],
        country: {},
        page: 1,
        pages: 1,
        item: {},
        filterActivated: false,
        filters: {
          search: '',
        },
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        image: null,
        name: '',
        lastname: '',
        position: '',
        period: '',
        email: '',
        order: 1,
        valid: true,
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => v.length >= 8 || '8 caracteres como mínimo',
          max: (v) => v.length <= 140 || 'Debe poser menos de 140 caracteres',
          maxNumber: (v) => v.length <= 18 || 'Debe poser menos de 18 caracteres',
          url: (v) => this.isURL(v) || 'La URL es inválida',
          email: (v) => (v ? /.+@.+\..+/.test(v) : true) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 1MB',
        },
      }
    },
    created() {
      if (this.association === 1 && this.$route.params.id) {
        this.getCompany()
      } else if (this.association === 1 && !this.$route.params.id) {
        this.router.push('/admin/dasboard')
      }
      this.getCountries()
      this.getDirectors()
    },
    computed: {
      title() {
        if (this.type === 2) {
          return this.$t('Views.AdminDirectors.title2')
        } else if (this.type === 1) {
          if (this.company.name) {
            return this.$t('Views.AdminDirectors.title3') + this.company.name
          } else {
            return this.$t('Views.AdminDirectors.title1')
          }
        }
      },
      headers() {
        return [
          { text: this.$t('Views.AdminDirectors.headerPosition'), value: 'position' },
          { text: this.$t('Views.AdminDirectors.headerName'), value: 'fullName' },
          { text: this.$t('Views.AdminDirectors.headerPeriod'), value: 'period' },
          { text: this.$t('Views.AdminDirectors.headerOrder'), value: 'order' },
          {
            text: '',
            value: 'actions',
            align: 'center',
            width: '100px',
          },
        ]
      },
      orderOptions() {
        return [
          {
            id: 1,
            name: this.$t('Views.AdminDirectors.orderOption1'),
            order: 'created_at',
            by: 'desc',
          },
          {
            id: 2,
            name: this.$t('Views.AdminDirectors.orderOption2'),
            order: 'created_at',
            by: 'asc',
          },
          {
            id: 3,
            name: this.$t('Views.AdminDirectors.orderOption3'),
            order: 'order',
            by: 'asc',
          },
          {
            id: 4,
            name: this.$t('Views.AdminDirectors.orderOption4'),
            order: 'updated_at',
            by: 'desc',
          },
        ]
      },
      orderBy() {
        return {
          id: 1,
          name: this.$t('Views.AdminDirectors.orderOption3'),
          order: 'order',
          by: 'desc',
        }
      },
    },
    methods: {
      countryName({ name, iso2 }) {
        if (name && iso2) {
          return `${name} (${iso2})`
        } else if (name) {
          return name
        }
      },
      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getDirectors()
      },
      async getCountries() {
        axios.get('countries?limit=300').then((response) => {
          this.countries = this.countries.concat(response.data)
        })
      },
      async getDirectors() {
        this.loading = true
        let orderString = ''
        let companyString = ''
        let typeString = '&type=' + this.type
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.association === 1 && this.$route.params.id) {
          companyString = '&company_id=' + this.$route.params.id
        } else {
          companyString = '&company_id=null'
        }
        axios
          .get('directors?page=' + this.page + orderString + typeString + companyString)
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            this.page = response.data.current_page
            this.pages = response.data.last_page
            this.items.map((x) => {
              x.fullName = x.name + ' ' + x.lastname
              x.image = x.uploads.find((upload) => upload.type === 'image')
            })
            this.loading = false
          })
      },

      async getCompany() {
        axios.get('companies/' + this.$route.params.id).then((response) => {
          this.company = response.data
        })
      },

      isURL(str) {
        if (!str || str === '') return true
        let url
        try {
          url = new URL(str)
        } catch (_) {
          return false
        }

        return url.protocol === 'http:' || url.protocol === 'https:'
      },

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.image = null
        this.country = {}
        this.name = ''
        this.lastname = ''
        this.position = ''
        this.email = ''
        this.period = ''
        this.order = 1
        this.$modal.show('crudModal')
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.image = null
        this.country =
          this.item.countries && this.item.countries.length > 0
            ? this.item.countries[0]
            : ''
        this.name = this.item.name
        this.lastname = this.item.lastname
        this.position = this.item.position
        this.email = this.item.email
        this.period = this.item.period
        this.order = this.item.order
        this.$modal.show('crudModal')
      },

      validate() {
        this.$refs.form.validate()
      },

      reset() {
        this.$refs.form.reset()
      },

      async createItem() {
        this.validate()
        let formData = new FormData()
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
        }
        if (this.association === 1 && this.$route.params.id && this.company.id) {
          formData.append('company_id', this.company.id)
        }
        if (this.image) {
          formData.append('file', this.image)
        }
        formData.append('countries[]', this.country.id)
        formData.append('fileType', 'image')
        formData.append('name', this.name)
        formData.append('lastname', this.lastname)
        formData.append('position', this.position)
        formData.append('email', this.email)
        formData.append('period', this.period)
        formData.append('order', this.order)
        formData.append('type', this.type)
        axios.post('directors', formData, this.config).then(() => {
          this.getDirectors()
          this.$modal.hide('crudModal')
        })
      },

      async saveItem() {
        this.validate()
        let formData = new FormData()
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
        }
        if (this.association === 1 && this.$route.params.id && this.company.id) {
          formData.append('company_id', this.company.id)
        }
        if (this.image) {
          formData.append('file', this.image)
          if (this.item.image && this.item.image.id) {
            formData.append('fileId', this.item.image.id)
          }
        }
        formData.append('countries[]', this.country.id)
        formData.append('fileType', 'image')
        formData.append('_method', 'patch')
        formData.append('name', this.name)
        formData.append('lastname', this.lastname)
        formData.append('position', this.position)
        formData.append('email', this.email)
        formData.append('period', this.period)
        formData.append('order', this.order)
        formData.append('type', this.type)
        axios.post('directors/' + this.item.id, formData, this.config).then(() => {
          this.getDirectors()
          this.$modal.hide('crudModal')
        })
      },

      async deleteItem(item) {
        axios.delete('directors/' + item.id).then((response) => {
          this.items = []
          this.getDirectors()
        })
      },

      // async deleteFile(id) {
      //   this.loading = true
      //   axios.delete('upload-management/delete-upload/' + id).then((response) => {
      //     this.getCompany()
      //     this.loading = false
      //     this.$swal({
      //       icon: 'success',
      //       title: `Eliminado`,
      //       showCancelButton: false,
      //       showConfirmButton: true,
      //     })
      //   })
      // },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getDirectors()
      },
    },
  }
</script>
<style lang="scss">
  @import '@/assets/scss/cruds/_crudStyles.scss';
</style>
